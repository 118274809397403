<template>
  <b-row>
    <b-col
      xs="12"
      md="6"
    >
      <b-card title="Hoşgeldiniz">
        <p>Berkay Tekstil Ticimax E-Ticaret Stok Güncelleme sistemine hoşgeldiniz.</p>
        <p>Günlük kayıtları sayfasından, güncelleme geçmişini görüntüleyebilir veya manuel güncelleme işlemi başlatabilirsiniz.</p>
        <p
          v-if="dashboard.id"
          class="text-success font-weight-bolder"
        >
          Son Güncelleme: {{ moment(dashboard.synced).format('DD.MM.YYYY HH:mm') }}
        </p>
        <b-button
          variant="primary"
          to="logs"
        >
          <FeatherIcon icon="ArchiveIcon" />
          Günlük Sayfasına Git
        </b-button>
      </b-card>
    </b-col>
    <b-col
      xs="12"
      md="6"
    >
      <b-img
        :src="imgUrl"
        fluid
      /></b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BImg,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  name: 'DashboardIndex',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BImg,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/register-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    dashboard() {
      return this.$store.getters['dashboard/getDashboard']
    },
  },
  created() {
    this.getDashboard()
  },
  methods: {
    getDashboard() {
      this.$store.dispatch('dashboard/dashboard')
    },
  },
}
</script>

<style scoped>

</style>
